import { useGetPageBannersQuery } from 'features/api/promoEngineApiSlice';
import { BANNER_UNIQUE_MODEL_API_KEY, PROMO_MODEL_API_KEY } from 'lib/datoCms/constants';
import { DatoCmsUniqueBanner } from 'lib/datoCms/types';
import { handleBanner } from 'lib/datoCms/utils';
import React, { memo } from 'react';
import { isTruthy } from 'utility/functions';
import { BannerHeroSkeleton } from '.';
import { BannerHero } from './BannerHero';

export const BannerHeroRenderer = memo(function BannerHeroRenderer({
  pageId,
  heroBanner,
  noPaddingLeft,
}: {
  pageId: string;
  heroBanner?: DatoCmsUniqueBanner;
  noPaddingLeft?: boolean;
}) {
  return (
    <React.Fragment>
      {heroBanner?._modelApiKey === PROMO_MODEL_API_KEY ? (
        <BannerPromoEngine noPaddingLeft={noPaddingLeft} pageId={pageId} />
      ) : null}
      {heroBanner?._modelApiKey === BANNER_UNIQUE_MODEL_API_KEY && isTruthy(heroBanner?.banners?.length) ? (
        <BannerHero noPaddingLeft={noPaddingLeft} banners={handleBanner(heroBanner!.banners!)} />
      ) : null}
    </React.Fragment>
  );
});

export const BannerPromoEngine = memo(function BannerPromoEngine({
  pageId,
  noPaddingLeft,
}: {
  pageId: string;
  noPaddingLeft?: boolean;
}) {
  const { data, isLoading, isError } = useGetPageBannersQuery(pageId, { refetchOnMountOrArgChange: true });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isError) return <React.Fragment />;
  else if (isLoading) {
    return <BannerHeroSkeleton />;
  } else if (data && !!data.heroBannerList?.length) {
    return <BannerHero noPaddingLeft={noPaddingLeft} banners={data.heroBannerList} />;
    // eslint-disable-next-line react/jsx-no-useless-fragment
  } else return <React.Fragment />;
});
