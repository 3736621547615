import { Banner, BannerImages } from '../BannerBasic';

import Image from 'next/image';
import React from 'react';
import styles from './BannerContent.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';

export type BannerContentProps = {
  children?: React.ReactNode;
  bannerContent: Partial<Banner> & {
    srcImage: Partial<BannerImages>;
  };
};

export const BannerContent: React.FC<BannerContentProps> = ({ bannerContent, children }: BannerContentProps) => {
  const labelPromo = useGetLabelByKey('promo')!;

  const { srcImage } = bannerContent ?? {};
  const { landscape, portrait, alt } = srcImage ?? {};

  return (
    <React.Fragment>
      {bannerContent.isPromo && <div className={styles.label}>{labelPromo}</div>}
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{bannerContent.title}</h2>
        <p className={styles.subtitle}>{bannerContent.subtitle}</p>
        {children}
      </div>
      <div className={styles.imageContainer}>
        {srcImage && (
          <>
            {landscape && (landscape.srcDesktop || landscape?.srcMobile) && (
              <>
                <div className={styles.mobile}>
                  <Image
                    alt={alt ?? ''}
                    src={landscape.srcMobile ?? landscape.srcDesktop}
                    fill
                    loading="eager"
                    priority
                    className={styles.image}
                  />
                </div>
                <div className={styles.desktop}>
                  <Image
                    alt={alt ?? ''}
                    src={landscape.srcDesktop ?? landscape.srcMobile}
                    fill
                    loading="eager"
                    priority
                    className={styles.image}
                  />
                </div>
              </>
            )}
            {portrait?.src && (
              <Image alt={alt ?? ''} src={portrait.src} loading="eager" priority className={styles.image} fill />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};
