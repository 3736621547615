import { Wrapper } from 'components/wrapper';
import classNames from 'classnames';
import styles from './SkeletonBox.module.scss';

export type SkeletonBoxProps = {
  width?: {
    mobile: string;
    desktop: string;
  };
  height?: {
    mobile: string;
    desktop: string;
  };
  marginTop?: {
    mobile: string;
    desktop: string;
  };
  marginBottom?: {
    mobile: string;
    desktop: string;
  };
  marginRight?: {
    mobile: string;
    desktop: string;
  };
  marginLeft?: {
    mobile: string;
    desktop: string;
  };
  className?: string;
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  fullWidth?: boolean;
};

export const SkeletonBox = ({
  width,
  height,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  className,
  noPaddingRight,
  noPaddingLeft,
  noPaddingTop,
  noPaddingBottom,
  fullWidth,
}: SkeletonBoxProps) => {
  return (
    <Wrapper
      fullWidth={fullWidth}
      noPaddingTop={noPaddingTop}
      noPaddingLeft={noPaddingLeft}
      noPaddingRight={noPaddingRight}
      noPaddingBottom={noPaddingBottom}
    >
      <div
        className={classNames(styles.container, className)}
        /* style={{
        width: isMobile ? `${width.mobile}` : `${width.desktop}`,
        height: isMobile ? `${height.mobile}` : `${height.desktop}`,
        marginTop: `${marginTop ? (isMobile ? `${marginTop.mobile}` : `${marginTop.desktop}`) : 0}`,
        marginBottom: `${marginBottom ? (isMobile ? `${marginBottom.mobile}` : `${marginBottom.desktop}`) : 0}`,
        marginRight: `${marginRight ? (isMobile ? `${marginRight.mobile}` : `${marginRight.desktop}`) : 0}`,
        marginLeft: `${marginLeft ? (isMobile ? `${marginLeft.mobile}` : `${marginLeft.desktop}`) : 0}`,
      }} */
      />
    </Wrapper>
  );
};
