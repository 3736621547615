import { BANNER_TYPE_HERO, BANNER_TYPE_LANDSCAPE } from './utils/constant';
import React, { useMemo } from 'react';

import { AnchorLink } from '../anchorLink';
import { BannerContent } from './bannerContent';
import { BannerGeneric } from './bannerGeneric';
import { BannerLink } from './bannerLink';
import { BannerType } from 'utility/types';
import { breakpoint } from '../../utility/constant';
import { gtag } from 'utility/functions';
import styles from './BannerBasic.module.scss';
import { useGetUrlPromo } from 'hooks/useGetPromoUrl';
import { useRouter } from 'next/router';
import useWindowSize from 'hooks/useWindowSize';

/* eslint-disable react/jsx-no-useless-fragment */

export type BannerBasicProps = {
  dataBanner: Banner;
  bannerType: BannerType;
};

export type Banner = {
  id: string;
  backgroundColor: string;
  isPromo: boolean;
  title: string;
  subtitle: string;
  // ctaLink: AnchorLinkType[];
  // ctaLabel: string;
  cta: {
    label: string | null;
    url: string | null;
  };
  backgroundPattern?: string;
  bannerType: string[];
  images: BannerImages;
};
export type BannerImages = {
  landscape?: {
    srcDesktop: string;
    srcMobile: string;
  };
  portrait?: {
    src: string;
  };
  alt: string;
};
export const BannerBasic: React.FC<BannerBasicProps> = ({ dataBanner, bannerType }: BannerBasicProps) => {
  const { width } = useWindowSize();
  const router = useRouter();
  const slug = router.asPath.split('/')[router.asPath.split('/').length - 1];

  const { backgroundPattern, cta, images, ...restOfBanner } = dataBanner || {};

  const getSrcImage = (images: BannerImages, bannerType: string): Partial<BannerImages> => {
    let landscape: { srcDesktop: string; srcMobile: string } | undefined,
      alt: string,
      portrait: { src: string } | undefined;
    if (bannerType === BANNER_TYPE_LANDSCAPE || bannerType === BANNER_TYPE_HERO) {
      landscape = images?.landscape;
      alt = images?.alt;
      return { landscape, alt };
    }
    (portrait = images?.portrait), (alt = images?.alt);
    return { portrait, alt };
  };
  const srcImage = useMemo(() => getSrcImage(images, bannerType), [images, bannerType]);
  const bannerData = {
    ...restOfBanner,
    srcImage,
  };

  const { href, target } = useGetUrlPromo(cta?.url ?? '', slug);
  // console.log('🚀 ~ href:', href, target);

  return (
    <React.Fragment>
      {dataBanner && (
        <React.Fragment>
          {width && width < breakpoint.sm ? (
            <BannerLink
              href={href}
              bannerType={bannerType}
              className={styles.container}
              style={{
                backgroundColor: dataBanner.backgroundColor,
                backgroundImage: `${backgroundPattern && 'url(' + backgroundPattern + ')'}`,
              }}
            >
              <BannerContent bannerContent={bannerData} />
            </BannerLink>
          ) : (
            <BannerGeneric
              className={styles.container}
              bannerType={bannerType}
              style={{
                backgroundColor: dataBanner.backgroundColor,
                backgroundImage: `${backgroundPattern && 'url(' + backgroundPattern + ')'}`,
              }}
            >
              <BannerContent bannerContent={bannerData}>
                {cta?.url && (
                  <AnchorLink
                    href={href}
                    target={target}
                    className={styles.cta}
                    onClick={() =>
                      gtag({ section: slug ?? 'Banner', detail: 'Banner', event: 'Click Scopri banner' }, true)
                    }
                  >
                    {cta.label}
                  </AnchorLink>
                )}
              </BannerContent>
            </BannerGeneric>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
