import { Icon, IconsEnum } from 'components/Icons';
import React, { useState } from 'react';

import { RichText } from 'components/richText';
import { SeoTablististType } from 'lib/datoCms/queries/getSeoData';
import { isTruthy } from 'utility/functions';
import styles from './TabsWithAccordion.module.scss';

export type TabsWithAccordionProps = {
  tabs: Array<SeoTablististType>;
  noPaddingLeft?: boolean;
};

export const TabsWithAccordion = ({ tabs }: TabsWithAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const onToggleClickHandeler = (id: string): void => {
    setExpanded((prev) => {
      const next = prev.filter((x) => x !== id);
      if (next.length === prev.length) {
        next.push(id);
      }
      return next;
    });
  };

  // console.log(JSON.stringify(tabs))

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isTruthy(tabs?.length)) return <React.Fragment />;

  const renderTitle = (id: string, title: string, index: number, prefix: string, isExpanded: boolean): JSX.Element => {
    if (index === 0) {
      return (
        <div className={styles[`${prefix}-title`]}>
          <h1 data-kind="title">{title}</h1>
          <Icon key={`${id}-${isExpanded}`} iconId={IconsEnum.CHEWRON_UP} />
        </div>
      );
    }

    return (
      <button className={styles[`${prefix}-title`]} onClick={() => onToggleClickHandeler(id)}>
        <h2 data-kind="title">{title}</h2>
        <Icon key={`${id}-${isExpanded}`} iconId={isExpanded ? IconsEnum.CHEWRON_UP : IconsEnum.CHEWRON_DOWN} />
      </button>
    );
  };

  const renderElement = ({ id, title, content }: SeoTablististType, index: number): JSX.Element => {
    const prefix = index === 0 ? 'main' : 'accordion';
    const isExpanded = index === 0 || expanded.includes(id);

    return (
      <div key={id} className={styles[prefix]}>
        {renderTitle(id, title, index, prefix, isExpanded)}
        <div className={styles[`${prefix}-wrapper`]}>
          <RichText
            containerClassName={[
              styles[`${prefix}-content`],
              styles[`${prefix}-${isExpanded ? 'expanded' : 'collapsed'}`],
            ].join(' ')}
          >
            {content}
          </RichText>
        </div>
      </div>
    );
  };

  return <div className={styles.container}>{tabs.map(renderElement)}</div>;
};
