import { BannerType } from 'utility/types';

export type BannerGenericProps = {
  style: React.CSSProperties;
  bannerType: BannerType;
  className: string;
  children: React.ReactNode;
};

export const BannerGeneric: React.FC<BannerGenericProps> = ({
  style,
  bannerType,
  children,
  className,
}: BannerGenericProps) => {
  return (
    <div className={className} style={style} data-banner-type={bannerType}>
      {children}
    </div>
  );
};
