import { getPromoUrl, getTargetType } from 'lib/promoEngine/utils';
import { useMemo } from 'react';

export const useGetUrlPromo = (url: string, slugProvenienza: string) => {
  return useMemo(() => {
    const href = getPromoUrl(url ?? '', slugProvenienza);
    return {
      href,
      target: getTargetType(href),
    };
  }, [url, slugProvenienza]);
};
